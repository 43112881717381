import React from "react"
import Contacts from "../components/contacts"
import styled from "styled-components"
import Seo from "../components/seo"
import { useEffect } from "react"

const Style = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  height: 100vh;
  overflow-x: hidden;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 840px;
    height: max-content;
  }

  .title {
    width: max-content;
    height: 58px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 52px;
    line-height: 58px;
    text-align: center;
    color: #111111;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 24px 0px;

    .bold {
      font-weight: 500;
    }
  }

  .text {
    width: 636px;
    height: 120px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #222222;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 24px 0px;
  }

  .button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 0px 0px;
    width: 636px;
    height: 76px;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 24px 0px;

    .button-link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 24px;
      border: 2px solid #007de6;
      box-sizing: border-box;
      border-radius: 60px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 16px;
      width: 310px;
      height: 60px;
      text-decoration: none;

      :hover {
        .button-text {
          color: #00aeff;
        }

        border: 2px solid #00aeff;
      }

      .button-text {
        width: max-content;
        height: 27px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #007de6;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
      }
    }
  }
  /* media queries for tablet */
  @media (max-width: 760px) {
    height: 100%;
    max-width: 100%;
    margin: 0;

    .container {
      margin: 0;
      align-items: center;
      justify-content: center;
    }
    .title {
      width: max-content;
      height: max-content;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 170%;
      line-height: 36px;
      color: #111111;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 22px 0px;
      text-align: left;
    }
    .text {
      width: 380px;
      height: 189px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #222222;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 22px 0px;
      text-align: left;
    }
    .button-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 22px 0px;

      .button-link {
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #007de6;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 10px 0px;

        .button-text {
          width: max-content;
          height: 27px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #007de6;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 10px;
        }
      }
    }
  }
  /* media queries for mobile */
  @media (max-width: 475px) {
    margin-bottom: 42px;
    .container {
      margin: 0;
    }
    .title {
      width: 308px;
      height: 72px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      line-height: 36px;
      color: #111111;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 22px 0px;
      text-align: left;
    }

    .text {
      width: 311px;
      height: 189px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #222222;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 22px 0px;
      text-align: left;
    }

    .button-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 310px;
      height: 116px;
      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 22px 0px;

      .button-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        border: 2px solid #007de6;
        box-sizing: border-box;
        border-radius: 60px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 16px 0px;

        .button-text {
          width: max-content;
          height: 27px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #007de6;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 10px;
        }
      }
    }
  }
`

function Installed() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "buttonClick",
      category: "PDF Pro Installed Successfully",
      action: "Accessed installed page",
      label: "Successfull installation of PDF Pro app",
    })
    // GA4 Event
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "TrialApplicationInstalled",
      category: "TrialApplicationInstalled",
      action: "Try PDF Pro - User succesfully installed trial application",
      label: "Try PDF Pro - User succesfully installed trial application",
    })

    // GA4 Event - staging
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "GA4 - Custom Event",
      eventName: "Successfull installation of PDF Pro app",
      action: "User has successfully installed PDF Pro app",
    })
  }, [])
  return (
    <>
      <Seo title="After installation"></Seo>
      <Style>
        <div className="container">
          <h1 className="title">
            Thank you For Installing <span className="bold">PDF Pro!</span>
          </h1>
          <span className="text">
            We hope you enjoy your evaluation of PDF Pro. To get the most out of
            our software, we encourage you to visit our knowledge base. If you
            have any questions on using PDF Pro, please feel free to submit a
            support ticket.
          </span>
          <div className="button-group">
            <a
              href="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
              className="button-link"
            >
              <span className="button-text">Knowledge Base</span>
            </a>
            <a
              href="https://support.pdfpro.com/hc/en-us/requests/new"
              className="button-link"
            >
              <span className="button-text">Submit a Support Request</span>
            </a>
          </div>
        </div>
      </Style>
      <Contacts></Contacts>
    </>
  )
}

export default Installed
